import React, { forwardRef } from "react";
import Robot from "../assets/images/home/robot.svg";
import Hand from "../assets/images/home/hand.svg";
import Telegram from "../assets/images/home/telegram.svg";

const Intro = forwardRef((props, ref) => {
    const openTelegram = () => {
        window.open("https://t.me/+8Q57jFzeDFU5M2Vk", "_blank");
    };

    return (
        <div id="home" className="container scroll" ref={ref}>
            <div className="block-title">
                <h1 className="title-1-2">
                    <span id="title-1">SMART</span>
                    {/* <span id="title-2">MASS</span> */}
                </h1>
                <div className="title-3">
                    <h1>
                        <span id="title-3">BOT</span>
                    </h1>
                    <div className="telegram" onClick={openTelegram}>
                        <p>Ne loupez rien ici !</p>
                        <img
                            id="telegram"
                            src={Telegram}
                            alt="Telegram"
                            width={40}
                        />
                    </div>
                    <img id="mimo" src={Robot} alt="Mimo" width={300} />
                </div>
            </div>
            <div className="block-hand">
                <img id="hand" src={Hand} alt="Prise de contrôle" width={500} />
            </div>
        </div>
    );
});

export default Intro;
