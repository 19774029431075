import React, { forwardRef } from "react";
import Lead from "../assets/images/home/lead.png";

const lead = forwardRef((props, ref) => {
    return (
        <div id="lead" ref={ref} className="container scroll">
            <img src={Lead} alt="Augmenter vos revenues" width={600} />
            <div className="block-explanation">
                <div>
                    <h2>Pourquoi utiliser nos services ?</h2>
                    <p>
                        Fatigué de recruter et former du personnel Voici les
                        avantages de notre service :
                    </p>
                    <p>
                        <strong>Visibilité accrue :</strong> Notre bot de
                        messages directs en masse (mass DM) augmente votre
                        visibilité, atteignant efficacement un plus large
                        public.
                    </p>
                    <p>
                        <strong>Automatisation :</strong> Nos bots automatisent
                        les tâches de base, vous économisant temps et argent
                        tout en éliminant la nécessité de former du personnel.
                    </p>
                    <p>
                        Simplifiez votre gestion et maximisez vos ressources
                        avec nos services !
                    </p>
                </div>

                {/* <div>
                    <h3>Pourquoi Instagram ? </h3>
                    <p>
                        Instagram est une plateforme idéale pour le marketing
                        direct grâce à son engagement élevé et à son large
                        public. Utiliser des DMs sur Instagram permet de toucher
                        efficacement vos clients potentiels.
                    </p>
                </div> */}
            </div>
        </div>
    );
});

export default lead;
