import React, { useState } from "react";
import Header from "../Header";
import Cart from "../Cart";

const withOverlay = (WrappedComponent) => {
    return (props) => {
        const [isOverlayVisible, setIsOverlayVisible] = useState(false);
        const [isActive, setIsActive] = useState(false);

        const toggleOverlay = () => {
            setIsOverlayVisible(!isOverlayVisible);
        };

        const setActive = () => {
            setIsActive(!isActive);
            toggleOverlay();
        };

        return (
            <>
                <Header setActive={setActive} />
                {isOverlayVisible && <div className="overlay"></div>}
                <WrappedComponent {...props} setActive={setActive} />
                <Cart setActive={setActive} isActive={isActive} />
            </>
        );
    };
};

export default withOverlay;
