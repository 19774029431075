import React from "react";
import withOverlay from "../components/hoc/withOverlay";
import Cards from "../components/Cards";

const Service = () => {
    return (
        <>
            <main>
                <Cards />
            </main>
        </>
    );
};

export default withOverlay(Service);
