import React, { useEffect, useState, useRef } from "react";
import withOverlay from "../components/hoc/withOverlay";
import Intro from "../components/Intro";
import Lead from "../components/lead";
import HIW from "../components/HIW";
// import FAQ from "../components/FAQ";

const Home = () => {
    const [activeSection, setActiveSection] = useState("");
    const introRef = useRef(null);
    const leadRef = useRef(null);
    const hiwRef = useRef(null);
    // const faqRef = useRef(null);

    useEffect(() => {
        const sections = [
            introRef.current,
            leadRef.current,
            hiwRef.current,
            // faqRef.current,
        ];

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    console.log("Entry:", entry);
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { threshold: 0.5 }
        );

        sections.forEach((section) => {
            if (section) observer.observe(section);
        });

        return () => {
            sections.forEach((section) => {
                if (section) observer.unobserve(section);
            });
        };
    }, []);

    const handleScrollToSection = (sectionRef) => {
        if (sectionRef && sectionRef.current) {
            sectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <main>
                <Intro ref={introRef} />
                <Lead ref={leadRef} />
                <HIW ref={hiwRef} />
                {/* <FAQ ref={faqRef} /> */}
            </main>
            <div className="fixed-sidebar">
                <div onClick={() => handleScrollToSection(introRef)}>
                    <div className={activeSection === "home" ? "active" : ""} />
                </div>
                <div onClick={() => handleScrollToSection(leadRef)}>
                    <div className={activeSection === "lead" ? "active" : ""} />
                </div>
                <div onClick={() => handleScrollToSection(hiwRef)}>
                    <div className={activeSection === "hiw" ? "active" : ""} />
                </div>
                {/* <div onClick={() => handleScrollToSection(faqRef)}>
                    <div className={activeSection === "faq" ? "active" : ""} />
                </div> */}
            </div>
        </>
    );
};

export default withOverlay(Home);
