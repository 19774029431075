import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import withOverlay from "../components/hoc/withOverlay";
import productAccounts from "../assets/images/products/accounts.png";
import productMassDM from "../assets/images/products/massdm.png";
import productScrap from "../assets/images/products/scrap.png";
import productReels from "../assets/images/products/reels.png";
import productMS from "../assets/images/products/ms.png";
import productTwitter from "../assets/images/products/twitter.png";
import productDa from "../assets/images/products/da.png";
import productOF from "../assets/images/products/of.png";
import productMinia from "../assets/images/products/miniature.svg";
import { CartContext } from "../pages/CardContext";

const products = [
    {
        id: 1,
        name: "Comptes Instagram",
        img: productAccounts,
        description: [
            "🌟 Comptes Neufs : Créés en 2024, jamais utilisés, parfaits pour un nouveau départ.",
            "📸 Tout est déjà prêt : Chaque compte est équipé d'une photo de profil et d'une bio qui sont toutes les deux cohérentes.",
            "👥 Créez votre propre communauté : Tous les comptes ne disposent d’aucun abonné, ce qui évite les potentiels faux comptes qui pourraient être abonnés à vous et ruiner votre crédibilité. Gagnez vos propres abonnés et construisez votre communauté.",
            "🔐 Comment vous connecter : Les informations de connexion (email et mot de passe) vous seront envoyées directement par mail dans cet ordre : Email / Mot de passe.",
            "🎯 Prix Compétitifs : Obtenez des comptes tout frais et à votre disposition quand vous le souhaitez à bas prix !",
        ],
        price: 0.25,
        stock: 10000,
    },
    {
        id: 2,
        name: "MassDM",
        img: productMassDM,
        description: [
            "🌟 Promouvez votre produit ou votre service grâce à nos bots disponibles 24h/24h afin d’agrandir votre clientèle.",
            "⚙️ Comment ça fonctionne ? Grâce aux mêmes comptes Instagram que nous vous proposons, nous allons envoyer le message de votre choix au public cible que vous souhaitez.",
            "🔐 Sécurité et Fiabilité : Tout est sécurisé, votre compte Instagram ne risque pas d’être restreint. Nos bots sont conçus exprès pour que tout soit fiable !",
            "🎯 Prix Compétitifs : Obtenez nos services avec des prix très avantageux et un gain certain de visibilité !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 3,
        name: "Liste Instagram Scrapé",
        img: productScrap,
        description: [
            "🌟 Obtenez une liste de personnes qui suivent le compte que vous avez ciblé, idéal pour savoir quels utilisateurs seraient intéressés par vos services",
            "⚙️ Comment ça fonctionne ? Grâce à notre bot conçu exprès nous pouvons ajouter chaque abonnées a un fichier excelle qui vous sera ensuite envoyé",
            "🔐 Sécurité et Fiabilité : La liste vous sera envoyé par mail sous le format d’un fichier excelle",
            "🎯 Prix Compétitifs : Obtenez la liste de vos potentiels client pour un prix plus que abordable",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 4,
        name: "Management Instagram",
        img: productReels,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 5,
        name: "Instagram MotherSlave",
        img: productMS,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 6,
        name: "Twitter Management",
        img: productTwitter,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 7,
        name: "DatingApps Management",
        img: productDa,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 8,
        name: "OF Manage",
        img: productOF,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
    {
        id: 9,
        name: "Minature",
        img: productMinia,
        description: [
            "🌟 Faites exploser votre compte Instagram grâce à nos bots “mother slave” qui s’abonneront, likeront et commenteront les comptes de votre choix.",
            "⚙️ Comment ça fonctionne ? Vous n’avez qu’à nous donner un profil Instagram et notre bot s’occupera d’aller s’abonner, liker les posts et commenter les posts de chaque personne suivant le compte donné.",
            "👥 Faites exploser votre nombre d’abonnés : Nous vous assurons un gain d’abonnés considérable ce qui vous permettra d’élargir votre communauté.",
            "🔐 Sécurité et Fiabilité : Aucun risque de restriction, tout a été conçu pour que votre compte reste intact.",
            "🎯 Prix Compétitifs : Obtenez un gain énorme de visibilité tout ça à bas prix !",
        ],
        price: 0.25,
        stock: 100000,
    },
];

const ProductDetails = () => {
    const [count, setCount] = useState(1);
    const inputRef = useRef(null);
    const { productId } = useParams();
    const product = products.find((p) => p.id === parseInt(productId));
    const { addToCart } = useContext(CartContext);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.width = `${
                inputRef.current.value.length + 1
            }ch`;
        }
    }, [count]);

    const increment = () => {
        setCount((prevCount) => {
            if (prevCount < product.stock) {
                return prevCount + 1;
            }
            return product.stock;
        });
    };

    const decrement = () => {
        setCount((prevCount) => {
            if (prevCount > 1) {
                return prevCount - 1;
            }
            return 1;
        });
    };

    const handleChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value)) {
            if (value < 0) {
                setCount(0);
            } else if (value > product.stock) {
                setCount(product.stock);
            } else {
                setCount(value);
            }
        }
    };
    const totalPrice = (count * product.price).toFixed(2);

    const handleAddToCart = () => {
        addToCart(product, count, totalPrice);
        alert(`Ajouté ${count} ${product.name} au panier !`);
    };

    if (!product) {
        return <div>Product not found</div>;
    }
    return (
        <div id="products" className="container scroll">
            <div className="block-des-img">
                <div className="block-img-product">
                    <img id="imgprod" src={product.img} alt="Smartphone" />
                </div>
                <div className="block-desc-btn">
                    <div className="desc">
                        <h3>Description du produit :</h3>
                        <div className="paragraphe">
                            {product.description.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                    <div className="block-btn">
                        <button id="btn-1">Acheter</button>
                        <button id="btn-2" onClick={handleAddToCart}>
                            Ajouter au panier
                        </button>
                    </div>
                </div>
                <div className="block-price-tot">
                    <div className="block-price">
                        <div>
                            <h2>Prix par compte</h2>
                            <p>{product.price} $ / pcs</p>
                        </div>
                        <div>
                            <h2>En stock</h2>
                            <p>{product.stock} pcs</p>
                        </div>
                        <div className="counter">
                            <button onClick={decrement}>-</button>
                            <input
                                type="number"
                                value={count}
                                onChange={handleChange}
                                className="input-as-span"
                                ref={inputRef}
                                min={1}
                                max={product.stock}
                            />
                            <button onClick={increment}>+</button>
                        </div>
                    </div>
                    <div className="block-fprice">
                        <h2>Totale : </h2>
                        <p>{totalPrice}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withOverlay(ProductDetails);
