import React, { forwardRef } from "react";
import Cible from "../assets/images/home/people.png";
import Message from "../assets/images/home/message.png";
import Send from "../assets/images/home/send.png";

const HIW = forwardRef((props, ref) => {
    return (
        <div id="hiw" className="HIW container scroll" ref={ref}>
            <h2>Comment ça marche ?</h2>
            <div className="block-imgs">
                <div className="block-ele">
                    <div className="ele-img">
                        <div>
                            <img src={Cible} alt="Cible" height={105} />
                        </div>
                        <p>Ciblage</p>
                    </div>
                    <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum lorem justo, ornare eget nisi sit amet,
                        sagittis blandit ante. Curabitur consectetur libero est,
                        id eleifend orci placerat non. Quisque pharetra lacinia
                        ligula. Vestibulum ornare quam sit amet sapien rhoncus,
                        sed luctus ipsum vestibulum. Aenean eget mollis mi.
                        Donec facilisis fringilla.
                    </span>
                </div>
                <div className="block-ele">
                    <div className="ele-img">
                        <div>
                            <img
                                src={Message}
                                alt="CibMMessageessagele"
                                height={105}
                            />
                        </div>
                        <p>contenu du message</p>
                    </div>
                    <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum lorem justo, ornare eget nisi sit amet,
                        sagittis blandit ante. Curabitur consectetur libero est,
                        id eleifend orci placerat non. Quisque pharetra lacinia
                        ligula. Vestibulum ornare quam sit amet sapien rhoncus,
                        sed luctus ipsum vestibulum. Aenean eget mollis mi.
                        Donec facilisis fringilla.
                    </span>
                </div>
                <div className="block-ele">
                    <div className="ele-img">
                        <div>
                            <img src={Send} alt="Envoyer" height={105} />
                        </div>
                        <p>Envoye du message</p>
                    </div>
                    <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum lorem justo, ornare eget nisi sit amet,
                        sagittis blandit ante. Curabitur consectetur libero est,
                        id eleifend orci placerat non. Quisque pharetra lacinia
                        ligula. Vestibulum ornare quam sit amet sapien rhoncus,
                        sed luctus ipsum vestibulum. Aenean eget mollis mi.
                        Donec facilisis fringilla.
                    </span>
                </div>
            </div>
        </div>
    );
});

export default HIW;
