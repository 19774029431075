import account from "../images/cards/accounts.svg";
import instagram from "../images/cards/instagram.svg";
import send from "../images/cards/sending.svg";
import reels from "../images/cards/reels.svg";
import scrap from "../images/cards/scrap.png";
import ms from "../images/cards/ms.svg";
import gear5 from "../images/cards/gear.svg";
import twitter from "../images/cards/twitter.svg";
import da from "../images/cards/da.png";
import of from "../images/cards/of.svg";
import pic from "../images/cards/photos.svg";
import ps from "../images/cards/photoshop.svg";
import magic from "../images/cards/magic.svg";

const products = [
    {
        id: 1,
        name: "Comptes Instagram",
        nbImg: 2,
        img1: instagram,
        alt1: "Instagram",
        img2: account,
        alt2: "Comptes",
    },
    {
        id: 2,
        name: "Mass DM",
        nbImg: 2,
        img1: instagram,
        alt1: "Instagram",
        img2: send,
        alt2: "Envoyer",
    },
    {
        id: 3,
        name: "Scraping",
        nbImg: 2,
        img1: instagram,
        alt1: "Instagram",
        img2: scrap,
        alt2: "Scraping",
    },
    {
        id: 4,
        name: "Manage Instagram",
        nbImg: 2,
        img1: instagram,
        alt1: "Instagram",
        img2: reels,
        alt2: "Reels",
    },
    {
        id: 5,
        name: "Instagram MotherSlave",
        nbImg: 2,
        img1: instagram,
        alt1: "Instagram",
        img2: ms,
        alt2: "Ms",
    },
    {
        id: 6,
        name: "Twitter Management",
        nbImg: 2,
        img1: twitter,
        alt1: "Twitter",
        img2: gear5,
        alt2: "Gear 5",
    },
    {
        id: 7,
        name: "DatingApps Manage",
        nbImg: 2,
        img1: da,
        alt1: "Dating Apps",
        img2: gear5,
        alt2: "Gear 5",
    },
    {
        id: 8,
        name: "OF Manage",
        nbImg: 2,
        img1: of,
        alt1: "Dating Apps",
        img2: pic,
        alt2: "Images",
    },
    {
        id: 9,
        name: "Minature",
        nbImg: 2,
        img1: ps,
        alt1: "Photoshop",
        img2: magic,
        alt2: "Magic",
    },
];

export default products;
