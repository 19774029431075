import React, { useContext, useRef, useEffect } from "react";
import CartSVG from "../assets/images/cart/Cart.svg";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../pages/CardContext";
import products from "../assets/data/dataproducts";

const Cart = ({ setActive, isActive }) => {
    const navigate = useNavigate();
    const blockCartsRef = useRef(null);
    // const { cartItems, removeCartItem, clearCart } = useContext(CartContext);
    const { cartItems, removeCartItem } = useContext(CartContext);

    const handleContinueShopping = () => {
        setActive();
        setTimeout(() => {
            navigate("/service");
        }, 300);
    };

    const handlePayment = () => {
        setActive();
        setTimeout(() => {
            navigate("/payment");
        }, 300);
    };

    const totalPrice = cartItems.reduce((total, item) => {
        return total + item.product.price * item.quantity;
    }, 0);

    useEffect(() => {
        const handleResize = () => {
            if (blockCartsRef.current) {
                const contentHeight = blockCartsRef.current.scrollHeight;
                if (contentHeight >= 500) {
                    blockCartsRef.current.style.gap = "20%";
                } else {
                    blockCartsRef.current.style.gap = "5%";
                }
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [cartItems]);

    return (
        <div className={`cart ${isActive ? "visible" : "hidden"}`}>
            <div className="cartsvg">
                <div onClick={setActive}>
                    <img src={CartSVG} alt="Fermer" width={30} />
                </div>
            </div>
            {cartItems.length === 0 ? (
                <div className="block-continue">
                    <h2>Votre panier est vide.</h2>
                    <div
                        className="btnContinue"
                        onClick={handleContinueShopping}
                    >
                        <p>Continuer les achats</p>
                    </div>
                </div>
            ) : (
                <div className="block-carts-tva">
                    <div className="block-carts" ref={blockCartsRef}>
                        {cartItems.map((item, index) => {
                            const product = products.find(
                                (p) => p.id === item.product.id
                            );

                            return (
                                <div key={index}>
                                    <div className="cart-item">
                                        <div className="block-img-un">
                                            <h3>{item.product.name}</h3>

                                            <div className="block-min-img">
                                                <img
                                                    src={product.img1}
                                                    alt={product.alt1}
                                                />
                                                <img
                                                    src={product.img2}
                                                    alt={product.alt2}
                                                />
                                            </div>
                                            <p>
                                                Prix unitaire: $
                                                {item.product.price.toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="block-btn-min">
                                            <div className="block-nte">
                                                <div className="nte">
                                                    <h3>Quantité :</h3>
                                                    <p>{item.quantity}</p>
                                                </div>
                                                <div className="nte">
                                                    <h3>Prix :</h3>
                                                    <p>
                                                        {" "}
                                                        {item.product.price *
                                                            item.quantity}
                                                        $
                                                    </p>
                                                </div>
                                            </div>

                                            <button
                                                onClick={() =>
                                                    removeCartItem(
                                                        item.product.id
                                                    )
                                                }
                                            >
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="block-tva">
                        <div>
                            <h2>Totale :</h2>
                            <p>{totalPrice.toFixed(2)}$</p>
                        </div>
                        <button onClick={handlePayment}>Payer</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Cart;
