import React, { createContext, useEffect, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem("cartItems");
        return savedCart ? JSON.parse(savedCart) : [];
    });

    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    const addToCart = (product, quantity, price) => {
        const existingItem = cartItems.find(
            (item) => item.product.id === product.id
        );

        if (existingItem) {
            const updatedItems = cartItems.map((item) =>
                item.product.id === product.id
                    ? {
                          ...item,
                          quantity: item.quantity + quantity,
                          price: parseFloat(item.price) + parseFloat(price),
                      }
                    : item
            );
            setCartItems(updatedItems);
        } else {
            setCartItems([...cartItems, { product, quantity, price }]);
        }
    };

    const removeCartItem = (productId) => {
        const updatedItems = cartItems.filter(
            (item) => item.product.id !== productId
        );
        setCartItems(updatedItems);
    };

    const clearCart = () => {
        setCartItems([]);
    };

    return (
        <CartContext.Provider
            value={{ cartItems, addToCart, removeCartItem, clearCart }}
        >
            {children}
        </CartContext.Provider>
    );
};
