import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Contact from "./pages/Contact";
import ProductDetails from "./pages/Products";
import { CartProvider } from "./pages/CardContext";
import Payment from "./pages/Pay";

function App() {
    return (
        <BrowserRouter>
            <CartProvider>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route
                        path="/product/:productId"
                        element={<ProductDetails />}
                    />
                    <Route path="/payment" element={<Payment />} />
                </Routes>
            </CartProvider>
        </BrowserRouter>
    );
}

export default App;
