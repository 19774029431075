import React from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";
import Loupe from "../assets/images/header/loupe.svg";
import Panier from "../assets/images/header/panier.svg";

const Header = ({ setActive }) => {
    const location = useLocation();
    const isActive =
        matchPath("/service", location.pathname) ||
        matchPath("/product/*", location.pathname);

    return (
        <header className="container">
            <ul className="block-nav">
                <NavLink
                    to="/"
                    className={(nav) => (nav.isActive ? "nav-active" : "")}
                >
                    Accueil
                </NavLink>
                <NavLink to="/service" className={isActive ? "nav-active" : ""}>
                    Services
                </NavLink>

                <NavLink
                    to="/contact"
                    className={(nav) => (nav.isActive ? "nav-active" : "")}
                >
                    Contact
                </NavLink>
            </ul>
            <div className="block-img">
                <div>
                    <img
                        className="img-links"
                        src={Loupe}
                        alt="Rechercher"
                        height={30}
                    />
                </div>
                <div onClick={setActive}>
                    <img
                        className="img-links"
                        src={Panier}
                        alt="Panier"
                        height={30}
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;
