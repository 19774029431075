import React from "react";
import { Link } from "react-router-dom";
import products from "../assets/data/dataproducts";

const cards = () => {
    const productsliced = [
        {
            id: 1,
            product: products.slice(0, 3),
        },
        {
            id: 2,
            product: products.slice(3, 6),
        },
        {
            id: 3,
            product: products.slice(6, 9),
        },
    ];

    return (
        <div id="cards" className="container">
            {productsliced.map((productbloc) => (
                <div key={productbloc.id}>
                    {productbloc.product.map((product) => (
                        <div key={product.id} className="block-services">
                            <h2>{product.name}</h2>
                            {product.nbImg > 1 ? (
                                <div className="block-icone">
                                    <img
                                        src={product.img1}
                                        alt={product.alt1}
                                    />
                                    <img
                                        src={product.img2}
                                        alt={product.alt2}
                                    />
                                </div>
                            ) : (
                                <img src={product.img1} alt={product.alt1} />
                            )}
                            <div className="buys">
                                <Link to={`/product/${product.id}`}>
                                    Acheter
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default cards;
