import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CartContext } from "../pages/CardContext";
import withOverlay from "../components/hoc/withOverlay";
import products from "../assets/data/dataproducts";

const PaymentPage = () => {
    const { cartItems, removeCartItem } = useContext(CartContext);
    const navigate = useNavigate();
    const [paymentLink, setPaymentLink] = useState(null);

    if (cartItems.length === 0) {
        navigate("/service");
        return null;
    }

    const totalPrice = cartItems.reduce((total, item) => {
        return total + item.product.price * item.quantity;
    }, 0);

    const handlePayment = async () => {
        try {
            const response = await axios.post(
                "https://api.commerce.coinbase.com/charges",
                {
                    name: "Achat de panier",
                    description: "Paiement pour les articles dans le panier",
                    pricing_type: "fixed_price",
                    local_price: {
                        amount: totalPrice.toFixed(2),
                        currency: "USD",
                    },
                    metadata: {
                        order_id: "order_" + new Date().getTime(),
                        source: "webstore",
                    },
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CC-Api-Key": "1884f361-9621-4cb9-b0fb-37ae6d1502d6",
                        "X-CC-Version": "2018-03-22",
                    },
                }
            );

            if (response.data && response.data.data) {
                setPaymentLink(response.data.data.hosted_url);
            }
        } catch (error) {
            console.error("Erreur lors de la création de la charge:", error);
        }
    };
    console.log(cartItems);

    return (
        <div className="pay container scroll">
            {/* <h1>Informations de Paiement</h1> */}

            <div className="ele-product">
                <div className="infos-pay">
                    <h2>Mon panier</h2>
                    <p>Le panier se videra dans 60 minutes</p>
                </div>
                <div>
                    {cartItems.map((item, index) => {
                        const product = products.find(
                            (p) => p.id === item.product.id
                        );

                        return (
                            <div key={index} className="pay-items">
                                <div className="block-min-img">
                                    <img
                                        src={product.img1}
                                        alt={product.alt1}
                                    />
                                    <img
                                        src={product.img2}
                                        alt={product.alt2}
                                    />
                                </div>
                                <div className="block-price-qnt">
                                    <span>{product.name}</span>
                                    <h3 id="price">
                                        {item.product.price * item.quantity}
                                    </h3>
                                    <p>Quantité : {item.quantity}</p>
                                </div>
                                <button
                                    id="del-checkout"
                                    onClick={() =>
                                        removeCartItem(item.product.id)
                                    }
                                >
                                    X
                                </button>
                            </div>
                        );
                    })}
                </div>
                <h2 id="tot-check" className="cc">
                    Total : {totalPrice.toFixed(2)}
                </h2>
            </div>
            <div className="block-paying">
                <div className="infos-pay">
                    <h2>TOTAL</h2>
                </div>
                <div className="block-total-check">
                    <h2>Total : </h2>
                    <h2 className="cc">{totalPrice.toFixed(2)}</h2>
                </div>
                <div className="block-total-check">
                    <h2>Livraison</h2>
                    <h2>0 jours</h2>
                </div>
                <button onClick={handlePayment}>Payer avec Crypto</button>
                {paymentLink && (
                    <button>
                        <a
                            href={paymentLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Payer maintenant
                        </a>
                    </button>
                )}
            </div>
        </div>
    );
};

export default withOverlay(PaymentPage);
